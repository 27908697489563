import { Navigate, Outlet } from "react-router-dom";
import jwt from "jwt-decode";

export default function ProtectedRoute({ redirectPath = "/" }) {
  const token = localStorage.getItem("@jarvis:token");

  let isValid = false;

  if (token) {
    const unscrypted = jwt(token);
    isValid =
      !!unscrypted.iat && !!unscrypted.cli_id && !!unscrypted.cli_expiracao;

    localStorage.setItem("@jarvis:nome", unscrypted.cli_nome);
    localStorage.setItem("@jarvis:telefone", unscrypted.cli_telefone);
    localStorage.setItem("@jarvis:email", unscrypted.cli_email);
    localStorage.setItem("@jarvis:localidade", unscrypted.cli_localidade);
  }

  if (!token || !isValid) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}
